import React, { useEffect } from 'react'
import Navbar from '../Components/Navbar'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, EffectFade } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import { motion } from "framer-motion"
import Footer from '../Components/Footer';
import { Link, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useRef } from 'react';
import userEvent from '@testing-library/user-event';

const Project = () => {

    const featuresRef = useRef(null)
    const descriptionRef = useRef(null)
    const mapRef = useRef(null)
    const { id } = useParams()
    const [data, setData] = useState([])
    const [images, setİmages] = useState()
    const [plan, setPlan] = useState()
    useEffect(() => {

        const formData = new FormData()
        formData.append('action', 'selectProjectbyId')
        formData.append('id', id)
        fetch(`${process.env.REACT_APP_BASE_URL}projects.php`, {
            method: 'POST',
            body: formData
        }).then(res => res.json())
            .then(res => {
                setPlan(res.plans.split(','))
                setİmages(res.images.split(','))
                document.title = res.name
                setData(res)

                featuresRef.current.innerHTML = res.features

                mapRef.current.innerHTML = res.konum
            })
    }, [])




    return (
        <div className='w-full min-h-screen flex flex-col items-start justify-start'>
            <Navbar></Navbar>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}

                className='w-full h-[75vh]  '>
                <Swiper
                    effect={'fade'}
                    pagination={{
                        dynamicBullets: true,
                    }}
                    modules={[Pagination, EffectFade]}
                    className='w-full h-screen bg-[#282828] border-white '
                >

                    {
                        images && images.map((item, index) => {
                            return (
                                <SwiperSlide className='relative h-screen w-full'>
                                    <motion.span
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 2 }}
                                        className='w-full h-full text-white text-5xl tracking-[10px] uppercase flex items-end pb-40  justify-end px-16  bg-black/40 absolute left-0 top-0' >
                                        <h1 className='lg:flex hidden items-center justify-center bg-black/60 p-4 rounded-sm'>
                                            {
                                                data && data.name
                                            }
                                        </h1>
                                    </motion.span>
                                    <img alt={index} src={process.env.REACT_APP_BASE_URL + item} className='w-full object-cover h-screen bg-center  '></img>
                                </SwiperSlide>
                            )
                        })
                    }


                </Swiper>
            </motion.div>
            <div className='w-full h-auto flex flex-col gap-6 mt-40 lg:mt-40 items-center justify-center'>
                <h1 className='text-4xl lg:hidden text-black font-bold flex lg:p-3 p-6 mt-4 ' >
                    <h1 className='font-semibold uppercase tracking-widest'>
                        {
                            data[1]
                        }
                    </h1>
                </h1>
                <motion.div
                    initial={{ opacity: 0, x: -500 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    className='w-full  h-full flex-col lg:flex-row flex items-start justify-center gap-6 xl:mt-44 mt-24 py-12 lg:mt-12 lg:px-20 px-12 '>
                    <div className='  w-full min-h-full h-full flex flex-col items-start rounded-md justify-start  '>
                        <div className='w-full flex items-center justify-start p-4 border-b '>
                            <a className='text-3xl font-light tracking-[5px] '>Proje Özellikleri</a>
                        </div>
                        <div ref={featuresRef} className=' w-full h-auto grid lg:grid-cols-2 gap-4 md:grid-cols-2 p-2 grid-cols-1'>

                        </div>
                    </div>
                </motion.div>


                <motion.div
                    initial={{ opacity: 0, x: -500 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    className='w-full grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 place-items-center gap-4 px-24 '
                >
                    {
                        plan && plan.map((item, index) => {
                            return (
                                <Link to={'/galleryDetail/'+id} className='group relative shadow-xl shadow-black/30 hover:shadow-none hover:scale-105 transition-all'>
                                    <img className='w-full h-full rounded-sm'  src={process.env.REACT_APP_BASE_URL + item}></img>
                                    <span className='absolute top-0 left-0 w-0 overflow-hidden transition-all flex items-center justify-center text-white h-full bg-black/50  group-hover:w-full'>
                                    Galeriye gitmek için tıklayınız.
                                    </span>
                                </Link>
                            )
                        })
                    }
                    {
                        images && images.map((item, index) => {
                            return (
                                <Link to={'/galleryDetail/'+id} className='group relative shadow-xl shadow-black/30 hover:shadow-none hover:scale-105 transition-all'>
                                    <img  className='w-full   h-full rounded-sm' src={process.env.REACT_APP_BASE_URL + item}></img>
                                    <span className='absolute top-0 left-0 w-0 overflow-hidden transition-all flex items-center justify-center text-white h-full bg-black/50  group-hover:w-full'>
                                    Galeriye gitmek için tıklayınız.
                                    </span>
                                </Link>
                            )
                        })
                    }


                </motion.div>


                <div ref={mapRef} className='w-full map flex items-center justify-center px-12 py-12 mt-12 bg-slate-50'>

                </div>
                <div className='w-full p-12 mt-12 '>
                    <iframe
                        className='w-full h-[100vh] rounded-sm'
                        src={data && data.youtube} title="COZY Rainy Library with Fireplace | Videos made to study rather than sleep"
                    >
                    </iframe>
                </div>

            </div>
            <Footer></Footer>

        </div>
    )
}

export default Project