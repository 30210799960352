import React from 'react'
import Navbar from '../Components/Navbar'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

const GalleryDetail = () => {


    const {id} = useParams()

    const [data, setData] = useState()
    useEffect(() => {

        const formData = new FormData()
        formData.append('id',id)
        formData.append('action', 'selectProjectbyId')
        fetch(`${process.env.REACT_APP_BASE_URL}projects.php`, {
            method: 'POST',
            body: formData
        }).then(res => res.json())
            .then(res => {
                setData(res)
            })
    }
        , [])



    const imageHandel =(imgPath)=>{

        const container = document.createElement('div')
        container.classList ='fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-[99]'

        const close = document.createElement('span')
        close.classList ='absolute top-5 z-[9999999999999999999999999999] right-5 text-white text-3xl cursor-pointer'

        const img = document.createElement('img')
        img.classList ='w-3/4 h-3/4 rounded-lg shadow-lg'
        img.src = process.env.REACT_APP_BASE_URL+imgPath
        

        container.addEventListener('click',()=>{
            container.remove()
        })




        container.appendChild(close)
        container.appendChild(img)

        document.body.appendChild(container)

    }


    return (
        <div className='w-full flex-col flex items-start justify-start min-h-screen'>
            <Navbar></Navbar>
            <div class="w-full flex flex-col gap-2 items-start justify-start p-4">
                <h1 class=" text-lg lg:text-3xl font-semibold uppercase  p-2">Proje görselleri</h1>
                <div className='w-full grid lg:grid-cols-4 place-items-center md:grid-cols-3 grid-cols-2 justify-start gap-4'>
                    {
                        data && data.images.split(',').map((item,index)=>{
                            return(
                                <img onClick={()=>imageHandel(item)}  src={process.env.REACT_APP_BASE_URL+item}></img>
                            )
                        })
                    }
                </div>
            </div>
            <div class="w-full flex flex-col gap-2 items-start justify-start p-4">
                <h1 class=" text-lg lg:text-3xl font-semibold uppercase  p-2">Plan görselleri</h1>
                <div className='w-full grid lg:grid-cols-4 place-items-center md:grid-cols-3 grid-cols-2 justify-start gap-4'>
                    {
                        data && data.plans.split(',').map((item,index)=>{
                            return(
                                <img onClick={()=>imageHandel(item)} src={process.env.REACT_APP_BASE_URL+item}></img>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default GalleryDetail