import React, { useRef } from 'react'
import { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
 

const AdminAbout = () => {


  const [text, setText] = useState('')
  const [file, setFile] = useState('')
  const [oldFile, setOldFile] = useState()
  const fileContainer = useRef()
  const [title,setTitle]=useState('')
  const [subTitle,setSubTitle]=useState('')
  useEffect(() => {
    const formData = new FormData()
    formData.append('action', 'select')
    fetch(`${process.env.REACT_APP_BASE_URL}about.php`, {
      method: 'POST',
      body: formData
    }).then(res => res.json())
      .then(res => {
        setText(res[0].text)
        setOldFile(res[0].image)
        setTitle(res[0].title)
        setSubTitle(res[0].subtitle)

      })
  }, [])


  const updateHandel = () => {

    const formData = new FormData()
    formData.append('action', 'update')
    formData.append('text', text)
    formData.append('title',title)
    formData.append('subtitle',subTitle)
    file && formData.append('image', file)
    fetch(`${process.env.REACT_APP_BASE_URL}about.php`, {
      method: 'POST',
      body: formData
    }).then(res => res.json())
      .then(res => {
        if(res.status!=200){
          toast.error(res.message)
        }else {
          toast.success(res.message)
        }
      })



  }

 
  return (
    <div className='w-full flex flex-col items-end justify-start gap-4 p-6 mt-12 lg:mt-0 h-screen'>
      <Toaster position='top-right'></Toaster>
      <div className='w-full flex flex-col items-start justify-start'>
        <p className='whitespace-nowrap  text-lg font-mono font-bold'>
          Resim seçiniz:
        </p>
        <label ref={fileContainer} htmlFor='resim'
          className='w-full cursor-pointer h-auto min-h-[5rem] border-dashed border border-black flex items-start justify-start rounded-md hover:bg-slate-100 transition-all'>
          {
            !file && <img className='h-40' src={process.env.REACT_APP_BASE_URL + oldFile}></img>
          }
          {
            file && <img className='h-40' src={URL.createObjectURL(file)}></img>
          }
        </label>
        <input onChange={(e) => { setFile(e.target.files[0]) }} type='file' id='resim' className='hidden' />
      </div>
      <div className='flex w-full flex-col items-start justify-start gap-1'>
        <label>
          Başlık
        </label>
        <input onChange={(e) => { setTitle(e.target.value) }} value={title} className='w-full h-12 rounded-md border border-gray-300 p-4' />
      </div>
      <div className='flex w-full flex-col items-start justify-start gap-1'>
        <label>
          Alt başlık
        </label>
        <input onChange={(e) => { setSubTitle(e.target.value) }} value={subTitle} className='w-full h-12 rounded-md border border-gray-300 p-4' />
      </div>
      <textarea onChange={(e) => { setText(e.target.value) }} value={text} className='w-full h-3/4 resize-none bg-slate-50 shadow-sm shadow-black/30 p-2 border-2 border-gray-300 rounded-md' placeholder='Hakkımızda' />
      <button onClick={(e) => { updateHandel() }} className='bg-blue-500  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4'>Kaydet</button>

    </div>
  )
}

export default AdminAbout