import React from 'react'
import { InputMask } from 'primereact/inputmask';
import { useState ,useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';

const AdminContact = () => {


  const [mail, setMail] = useState('')
  const [phone, setPhone] = useState('')
  const [instagram, setInstagram] = useState('')
  const [facebook, setFacebook] = useState('')
  const [konum, setKonum] = useState('')

  useEffect(() => {
    const formData = new FormData()
    formData.append('action','select')
    fetch(`${process.env.REACT_APP_BASE_URL}contact.php`,{
      method:'POST',
      body:formData
      }).then(res=>res.json())
      .then(res=>{
        setMail(res.mail)
        setPhone(res.phone)
        setInstagram(res.instagram)
        setFacebook(res.facebook)
        setKonum(res.location)
      })
  }
  , [])

  const updateHandel = ()=>{
    const formData = new FormData()
    formData.append('action','update')
    formData.append('mail',mail)
    formData.append('phone',phone)
    formData.append('instagram',instagram)
    formData.append('facebook',facebook)
    formData.append('konum',konum)
    fetch(`${process.env.REACT_APP_BASE_URL}contact.php`,{
      method:'POST',
      body:formData
      }).then(res=>res.json())
      .then(res=>{
        if(res.status!=200){
          toast.error(res.message)
        }else {
          toast.success(res.message)
        }
      })
  }


  return (
    <div className='w-full h-screen flex flex-col items-start justify-start p-4 mt-12 md:mt-0'>
      <Toaster position='top-right'></Toaster>
        <div className='w-full grid md:grid-cols-2 grid-cols-1 place-items-center p-6 gap-6'>
          <div className='w-full flex flex-col items-start justify-start gap-2'>
            <label className='text-2xl'>
              Eposta adresi
            </label>
          <input type='text' onChange={(e)=>{setMail(e.target.value)}} value={mail} className='w-full h-12 bg-slate-50 shadow-sm shadow-black/30 p-2 border-2 border-gray-300 rounded-md' />
          </div>
          <div className='w-full flex flex-col items-start justify-start gap-2'>
            <label className='text-2xl'>
              Telefon numarası
            </label>
            <InputMask onChange={(e)=>{setPhone(e.value)}} value={phone}  mask="999-999-99-99" placeholder="999-999-99-99" className='w-full h-12 bg-slate-50 shadow-sm shadow-black/30 p-2 border-2 border-gray-300 rounded-md'/>
          </div>
          <div className='w-full flex flex-col items-start justify-start gap-2'>
            <label className='text-2xl'>
              İnstagram adresi
            </label>
          <input type='text' onChange={(e)=>{setInstagram(e.target.value)}} value={instagram}  className='w-full h-12 bg-slate-50 shadow-sm shadow-black/30 p-2 border-2 border-gray-300 rounded-md' />
          </div>
          <div className='w-full flex flex-col items-start justify-start gap-2'>
            <label className='text-2xl'>
              Facebook adresi
            </label>
          <input type='text' onChange={(e)=>{setFacebook(e.target.value)}} value={facebook}  className='w-full h-12 bg-slate-50 shadow-sm shadow-black/30 p-2 border-2 border-gray-300 rounded-md' />
          </div>
          <div className='w-full flex flex-col items-start justify-start gap-2'>
            <label className='text-2xl'>
            Konum
            </label>
          <input type='text' onChange={(e)=>{setKonum(e.target.value)}} value={konum}  className='w-full h-12 bg-slate-50 shadow-sm shadow-black/30 p-2 border-2 border-gray-300 rounded-md' />
          </div>
          
          <div></div>
          <button onClick={(e)=>{updateHandel()}} className='w-full hover:bg-indigo-400 bg-indigo-600 h-full p-4 rounded-md text-white text-2xl'> 
            Kaydet
          </button>
         

        </div>
    </div>
  )
}

export default AdminContact