import React from 'react'
import Navbar from '../Components/Navbar'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const Gallery = () => {


    const [bg, setBg] = useState('')
    useEffect(() => {

        const formData = new FormData()
        formData.append('action', 'select')
        fetch(`${process.env.REACT_APP_BASE_URL}projects.php`, {
            method: 'POST',
            body: formData
        }).then(res => res.json())
            .then(res => {
                setBg(res)
            })
    }
        , [])
    return (
        <>
            <Navbar></Navbar>
            <div className='w-full flex flex-wrap lg:items-start lg:justify-start items-center justify-center place-items-start p-1 gap-4 h-screen mt-10 '>
                {
                    bg && bg.map((item, index) => {

                        const b = item.images.split(',')[0]
                        return (
                            <Link to={'/galleryDetail/'+item.id} class="w-96 shadow-black drop-shadow-md hover:drop-shadow-2xl flex h-1/2 bgtr aspect-square relative group " href="/gallery/14">
                                <div class="w-full flex flex-col gap-2 items-center group-hover:z-10 transition-all text-white text-2xl uppercase justify-center bg-black/50 absolute top-0 left-0 h-full -z-10 ">
                                    <h1>{item.name}</h1>
                                    <h4 class="text-base">İncele</h4>
                                </div>
                                <img src={process.env.REACT_APP_BASE_URL+b} class="w-full h-full md:object-cover object-cover "/>
                            </Link>
                        )
                    })
                }
            </div>
        </>
    )
}

export default Gallery