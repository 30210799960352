import React from 'react'
import Navbar from '../Components/Navbar'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper/modules';
import bg from '../Assets/bg.jpg'
import logoBg from '../Assets/logoBg.png'
import mb from '../Assets/mb.png'
import 'swiper/css/pagination';
import { motion } from 'framer-motion';
import About from '../Pages/About';
import { useEffect , useState } from 'react';
import { Link } from 'react-router-dom';

const Home = () => {

    const [data,setData] = useState([])
    const [bg,setBg]= useState('')
    useEffect (()=>{
        document.title = 'Anasayfa'

        const formData = new FormData()
        formData.append('action','selectAll')
         fetch(`${process.env.REACT_APP_BASE_URL}projects.php`,{
          method:'POST',
          body:formData
          }).then(res=>res.json())
          .then(res=>{
            setData(res)
          })
      }
        , [])

    return (
        <div className='flex w-full items-start justify-between flex-col min-h-screen '>
            <Navbar></Navbar>
            <div className='w-full lg:flex h-full hidden items-start justify-start flex-1'>
            <Swiper
                pagination={{
                    dynamicBullets: true,
                }}
                modules={[Pagination]}
                className='w-full h-screen md:h-[86vh] flex-1 bg-[#282828] border-white '
            >
               
               {
                data?.map((item,index)=>{
                   const bg = item.images.split(',')
                //    const a= bg[0].replace('[','').replace('"','').replace('"','').replace('\\','')
                

                    return(
                        
                        <SwiperSlide key={index}>
                     
                       <motion.div
                        initial={{opacity:0}}
                        animate={{opacity:1}}
                        transition={{duration:3}}
                         className='w-full h-full flex-1 relative '  >
                            <img src={process.env.REACT_APP_BASE_URL+bg[0]} className='w-full md:object-cover object-contain h-screen absolute -z-20 left-0 top-0'></img>
                            <span className='w-full h-full absolute -z-10 top-0 left-0 bg-black/30'/>
                            <div className='p-12 z-[9999] w-full h-full flex items-end justify-start'>
                                <div className='w-1/2 flex-col flex items-start justify-start gap-6'>
                                    <h1 className='cursor-pointer ml-4 antialiased text-lg md:text-5xl font-bold text-white'>{item.name.replace('_',' ')}</h1>
                                    
                                    <Link to={'/project/'+item.id} className='cursor-pointer text-sm md:text-lg transition-all font-normal tracking-widest uppercase text-white bg-[#282828] hover:bg-white hover:text-black p-2 rounded-sm'>Detaylı Bilgi</Link>
                                </div>
                            </div>
                        </motion.div>
                     
                    </SwiperSlide>
                    );
                })
               }
                
                
            </Swiper>
            </div>
            <div className='w-full lg:hidden min-h-screen flex flex-col items-start justify-start '>
                <motion.div 
                initial={{opacity:0}}
                animate={{opacity:1}}
                transition={{duration:1}}
                style={{backgroundImage:`url(${mb})`} }
                className='w-full h-screen flex flex-col relative gap-6 items-center bg-[#d7d7d7] justify-center'>
                    <img src={logoBg} className='w-2/3 invert bg-transparent'  />
                    <motion.div
                    initial={{opacity:0}}
                    animate={{opacity:1}}
                    transition={{duration:5.5}}
                    className='w-full flex items-center justify-center'>
                    <h1 className='font-medium tracking-[5px] text-base'>AYRICAKLIKLI BİR YAŞAM</h1>
                    </motion.div>
                   
                </motion.div>
                <About></About>
                


            </div>
        </div>
    )
}

export default Home